import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import useSiteData from './hooks/useSiteData';
import Layout from './components/layout/Layout';
import Homepage from './components/pages/Homepage';
import ArticleDetails from './components/pages/ArticleDetails';
import LocalNewsPage from './components/pages/LocalNewsPage';
import AboutPage from './components/pages/AboutPage';
import ContactPage from './components/pages/ContactPage';
import InsightsPage from './components/pages/InsightsPage';

const MatomoTagManager = () => {
  const location = useLocation();

  useEffect(() => {
    const loadMatomoTagManager = () => {
      if (!window._mtm) {
        const _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date()).getTime(), event: 'mtm.Start' });
        const g = document.createElement('script'),
          s = document.getElementsByTagName('script')[0];
        g.async = true;
        g.src = 'https://analytics.jlikr.com/js/container_5CdFsFY2.js';
        s.parentNode.insertBefore(g, s);
      }
    };

    loadMatomoTagManager();
  }, []);

  useEffect(() => {
    if (window._mtm) {
      window._mtm.push({ event: 'mtm.PageView', url: window.location.href });
    }
  }, [location]);

  return null;
};

function App() {
  const { siteData, isLoading } = useSiteData();

  return (
    <Router>
      <MatomoTagManager />
      <Layout siteData={siteData} isLoading={isLoading}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/:year/:month/:day/:slug" element={<ArticleDetails />} />
          <Route path="/local-news" element={<LocalNewsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/insights" element={<InsightsPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
