import React from 'react';
import Button from '../common/Button';
import Tag from '../articles/Tag';
import { useNavigate } from 'react-router-dom';
import './HeroSection.css';
import useArticleData from '../../hooks/useArticleData';

const HeroSection = () => {
  const navigate = useNavigate();
  const { featuredArticles, isLoading } = useArticleData();

  const featuredArticle = featuredArticles[0]; 

  if (isLoading) return <div>Loading...</div>;
  if (!featuredArticle) return <div>No featured article available.</div>;

  const { attributes } = featuredArticle;
  const { article_title, article_content, article_category, article_image, article_scraped_image_url, slug } = attributes;

  const imageUrl = article_image.data && article_image.data.length > 0
    ? `${process.env.REACT_APP_STRAPI_BASE_URL}${article_image.data[0].attributes.url}`
    : article_scraped_image_url || 'path/to/default/image.jpg';

  const contentPreview = article_content ? `${article_content.substring(0, 330)}...` : '';

  const articleDate = new Date(attributes.article_DateTimeStamp);
  const formattedDate = `${articleDate.toLocaleDateString()} ${articleDate.toLocaleTimeString()}`;


  console.log(attributes);

  const goToArticleDetails = () => {
    const date = new Date(attributes.article_posted_date);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    
    const url = `/${year}/${month}/${day}/${attributes.slug}`;
    console.log('Slug:', attributes.slug);
    console.log('URL:', url);
    navigate(url);
  };

  return (
    <section className="hero-section">
      <div className="hero-card" onClick={goToArticleDetails} style={{ cursor: 'pointer' }}>
        <div className="hero-image-container">
          <img className="hero-section-image" src={imageUrl} alt={article_title || 'Featured Article'} />
        </div>
        <div className="text-area">
          {article_category && <Tag text={article_category} />}
          <h1 className="hero-headline">{article_title}</h1>
          <p dangerouslySetInnerHTML={{ __html: contentPreview }} className="paragraph"></p>
          <p className="article-date">{formattedDate}</p>
          <Button text="Read Now" className="button" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;