import React from 'react';
import usePageData from '../../hooks/usePageData';

const InsightsPage = () => {
  const { pageData, isLoading, error } = usePageData(3); // Assuming ID 3 for Insights page

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const pageTitle = pageData?.attributes?.page_title;
  const heroImageUrl = pageData?.attributes?.hero_image?.data?.[0]?.attributes?.url 
                       ? `${process.env.REACT_APP_STRAPI_BASE_URL}${pageData.attributes.hero_image.data[0].attributes.url}` 
                       : '';

  return (
    <>
      {pageTitle && <h1>{pageTitle}</h1>}
      {heroImageUrl && <img src={heroImageUrl} alt="Hero for Insights Page" />}
    </>
  );
};

export default InsightsPage;
