// Layout.jsx
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Layout.css';

const Layout = ({ children, siteData, isLoading }) => {

  return (
    <>
      <Header siteData={siteData} />
      {isLoading ? (
        <div className={`loading-spinner-container ${isLoading ? 'show' : ''}`}>
          <div className="spinner"></div>
        </div>
      ) : (
        <main className="main-content">{children}</main>
      )}
      <Footer siteData={siteData} />
    </>
  );
};

export default Layout;
