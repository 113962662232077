// NavigationMenu.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationMenu.css';

const NavigationMenu = ({ items = [], socialMediaLinks = [], isMenuOpen, onLinkClick }) => (
  <>
    <nav className={`navigation-menu ${isMenuOpen ? 'nav-active' : ''}`}>
      {items.map((item, index) => (
        <Link key={index} to={item.link_url} className={`navigation-item ${item.is_Featured_Link ? 'navigation-item-highlighted' : ''}`} onClick={onLinkClick}>
          {item.link_name}
        </Link>
      ))}
    </nav>
    {isMenuOpen && (
      <div className="social-media-footer">
        {socialMediaLinks.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="social-media-link" onClick={onLinkClick}>
            <img src={link.logo_url.startsWith('http') ? link.logo_url : `${process.env.REACT_APP_STRAPI_BASE_URL}${link.logo_url}`} alt={link.platform} className="social-icon" />
          </a>
        ))}
      </div>
    )}
  </>
);

export default NavigationMenu;
