import React, { useEffect, useState, useCallback } from 'react';
import ArticleCard from '../articles/ArticleCard';
import HeroSection from '../layout/HeroSection';
import TagContainer from '../../hooks/TagContainer';
import useArticleData from '../../hooks/useArticleData';
import useHomepageData from '../../hooks/useHomepageData';

const Homepage = () => {
  const { nonFeaturedArticles, isLoading, hasMoreNonFeatured, loadMoreNonFeaturedArticles } = useArticleData();
  const { homepageData } = useHomepageData();
  const [selectedTag, setSelectedTag] = useState(null);

  const onTagClick = (tag) => {
    setSelectedTag(prevTag => (prevTag === tag ? null : tag));
  };

  const filteredArticles = selectedTag
    ? nonFeaturedArticles.filter(article => article.attributes.article_category === selectedTag)
    : nonFeaturedArticles;

  const sortedArticles = filteredArticles.sort((a, b) => new Date(b.attributes.article_DateTimeStamp) - new Date(a.attributes.article_DateTimeStamp));

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
      loadMoreNonFeaturedArticles();
    }
  }, [loadMoreNonFeaturedArticles]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  if (isLoading && nonFeaturedArticles.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {homepageData?.homepage_title && (
        <div className="homepage-title">
          <h2>{homepageData.homepage_title}</h2>
        </div>
      )}
      <HeroSection />
      <TagContainer onTagClick={onTagClick} selectedTag={selectedTag} />
      <section className="articles">
        {sortedArticles.length > 0 ? (
          sortedArticles.map(article => (
            <ArticleCard key={article.id} article={article} />
          ))
        ) : (
          <p>No articles found.</p>
        )}
        {isLoading && <p>Loading more articles...</p>}
      </section>
    </>
  );
};

export default Homepage;
