// useHomepageData.js
import { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';

const useHomepageData = () => {
  const [homepageData, setHomepageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    const fetchHomepageData = async () => {
      setIsLoading(true);
      try {
          const response = await axiosInstance.get(`/api/homepage?populate[Seo]=*&populate[homepage_logo]=*`);
        setHomepageData(response.data.data.attributes);
      } catch (err) {
        console.error('Error fetching homepage data:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHomepageData();
  }, []);

  

  return { homepageData, isLoading, error };
  
};

export default useHomepageData;
