// Button.jsx 
import React from 'react';
import './Button.css';

const Button = ({ text, highlighted = false, onClick }) => (
  <button 
    className={`button ${highlighted ? 'button-highlighted' : ''}`} 
    onClick={onClick}
  >
    {text}
  </button>
);

export default Button;
