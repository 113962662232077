import React from 'react';
import useArticleData from '../../hooks/useArticleData';
import Logo from '../common/Logo';
import SocialMedia from '../common/SocialMedia';
import './Footer.css';

const Footer = ({ siteData }) => {
  const { websiteLogo = '', websiteTitle = '', footerNavigationLinks = [], footerText = '', socialMediaLinks = [] } = siteData ?? {};
  const { latestArticles } = useArticleData();

  const groupedLinks = footerNavigationLinks.reduce((acc, link) => {
    const { footerLinkColumns, text, path } = link;
    if (footerLinkColumns !== 'Latest News') {
      acc[footerLinkColumns] = acc[footerLinkColumns] || [];
      acc[footerLinkColumns].push({ text, path });
    }
    return acc;
  }, {});

  const columns = Object.keys(groupedLinks).map(columnTitle => ({
    title: columnTitle,
    links: groupedLinks[columnTitle]
  }));

  return (
    <footer className="footer">
      <div className="top-row">
        <Logo logoUrl={websiteLogo} siteName={websiteTitle} />
      </div>

      <div className="link-column-container">
        {columns.map((column, index) => (
          <div className="link-column" key={index}>
            <h3 className="link-column-title">{column.title}</h3>
            <ul className="link-list">
              {column.links.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <a href={link.path} className="footer-link">{link.text}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className="link-column latest-news">
          <h3 className="link-column-title">Latest News</h3>
          <ul className="link-list">
            {latestArticles.length > 0 ? (
              latestArticles.slice(0, 5).map((article, index) => {
                const date = new Date(article.attributes.article_posted_date);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                const url = `/${year}/${month}/${day}/${article.attributes.slug}`;

                return (
                  <li key={index}>
                    <a href={url} className="footer-link">{article.attributes.article_title}</a>
                  </li>
                );
              })
            ) : (
              <li>Loading latest articles...</li>
            )}
          </ul>
        </div>

      </div>

      <div className="bottom-row">
        <SocialMedia icons={socialMediaLinks} />
        <p className="footer-text">{footerText}</p>
      </div>
    </footer>
  );
};

export default Footer;