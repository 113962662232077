// Tag.jsx
import React from 'react';
import './Tag.css';

 // Tag.jsx
const Tag = ({ text, onClick, isSelected }) => {

  const handleTouchEnd = (e) => {
    // Prevent the default mousedown event
    e.preventDefault();

    // Call the onClick handler
    if(onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={`tag ${isSelected ? 'selected' : ''}`}
      onTouchEnd={handleTouchEnd} // Use onTouchEnd for mobile devices
      onClick={onClick} // onClick still needed for non-touch devices
    >
      {text}
    </button>
  );
};


export default Tag;
