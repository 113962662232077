import React from 'react';
import Tag from './Tag';
import './ArticleCard.css';
import { useNavigate } from 'react-router-dom';

const ArticleCard = ({ article }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    const date = new Date(article.attributes.article_posted_date);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    
    const url = `/${year}/${month}/${day}/${article.attributes.slug}`;
    navigate(url);
  };

  const articleDate = new Date(article.attributes.article_DateTimeStamp);
  const formattedDate = `${articleDate.toLocaleDateString()} ${articleDate.toLocaleTimeString()}`;


  const imageUrl = article.attributes.article_image.data?.[0]?.attributes?.url
    ? `${process.env.REACT_APP_STRAPI_BASE_URL}${article.attributes.article_image.data[0].attributes.url}`
    : article.attributes.article_scraped_image_url || '';

    return (
      <div className="article-card" onClick={handleNavigate}>
        <img src={imageUrl} alt={article.attributes.article_title} className="article-image" />
        <div className="article-text-area">
          <Tag text={article.attributes.article_category} />
          <h3 className="article-title">{article.attributes.article_title}</h3>
          <p className="article-date">{formattedDate}</p>
          <span className="article-button">Read More</span>
        </div>
      </div>
    );
  };

export default ArticleCard;