import { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';

const useSiteData = () => {
  const [siteData, setSiteData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchSiteData() {
      setIsLoading(true);
      try {
        const siteDataResponsePromise = axiosInstance.get('/api/site-data?populate[seo]=*&populate[social_media_links]=*&populate[website_logo]=*');
        const headerNavResponsePromise = axiosInstance.get('/api/navigations/1?populate[navigation_links]=*');
        const footerNavResponsePromise = axiosInstance.get('/api/navigations/2?populate[navigation_links]=*');

        const [siteResponse, headerNavResponse, footerNavResponse] = await Promise.all([
          siteDataResponsePromise, 
          headerNavResponsePromise, 
          footerNavResponsePromise
        ]);

        const activeHeaderNavigationItems = headerNavResponse.data.data.attributes.navigation_links.filter(item => item.isActive);
        const activeFooterNavigationItems = footerNavResponse.data.data.attributes.navigation_links.filter(item => item.isActive);

        const websiteLogoData = siteResponse.data.data.attributes.website_logo.data;
        const websiteLogoUrl = websiteLogoData && websiteLogoData.length > 0 
          ? `${process.env.REACT_APP_STRAPI_BASE_URL}${websiteLogoData[0].attributes.url}` 
          : '';

        const siteDataFormatted = {
          websiteTitle: siteResponse.data.data.attributes.website_title,
          websiteLogo: websiteLogoUrl,
          socialMediaLinks: siteResponse.data.data.attributes.social_media_links.map(link => {
            return {
              ...link,
              logo_url: link.logo_url || ''
            };
          }),
          seo: siteResponse.data.data.attributes.seo,
          navigationItems: activeHeaderNavigationItems,
          footerNavigationLinks: activeFooterNavigationItems.map(link => ({
            text: link.link_name,
            path: link.link_url,
            highlighted: link.is_Featured_Link,
            footerLinkColumns: link.footer_link_columns
          })),
          footerText: siteResponse.data.data.attributes.footer_text,
        };

        setSiteData(siteDataFormatted);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    fetchSiteData();
  }, []);

  return { siteData, isLoading };
};

export default useSiteData;
