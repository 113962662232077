// ContentSection.jsx
import React from 'react';

const ContentSection = ({ headline, htmlContent }) => {
  return (
    <section className="content-section">
      <h2>{headline}</h2>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </section>
  );
};

export default ContentSection;
