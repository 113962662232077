import { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';

const useArticleData = () => {
  const [allArticles, setAllArticles] = useState([]);
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [nonFeaturedArticles, setNonFeaturedArticles] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);
  const [articleDetails, setArticleDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nonFeaturedPage, setNonFeaturedPage] = useState(1);
  const [hasMoreNonFeatured, setHasMoreNonFeatured] = useState(true);

  useEffect(() => {
    const fetchAllArticles = async () => {
      setIsLoading(true);
      try {
        const generateUrl = (article) => {
          const date = new Date(article.article_posted_date);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `/${year}/${month}/${day}/${article.article_slug}`;
        };

        const { data: allData } = await axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*`);
        setAllArticles(allData.data.map(article => ({ ...article, url: generateUrl(article) })));

        const { data: featuredData } = await axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=true`);
        setFeaturedArticles(featuredData.data.map(article => ({ ...article, url: generateUrl(article) })));

        const { data: latestData } = await axiosInstance.get(`/api/articles?populate[SEO]=*&populate[article_image]=*&_sort=published_at:DESC&_limit=4`);
        setLatestArticles(latestData.data.map(article => ({ ...article, url: generateUrl(article) })));

        // Initial fetch for non-featured articles
        const { data: nonFeaturedData } = await axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=false&pagination[page]=1&pagination[pageSize]=25`);
        setNonFeaturedArticles(nonFeaturedData.data.map(article => ({ ...article, url: generateUrl(article) })));
        setHasMoreNonFeatured(1 < nonFeaturedData.meta.pagination.pageCount);
        
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllArticles();

    const timerId = setInterval(fetchAllArticles, 300000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (nonFeaturedPage > 1) {
      const fetchNonFeaturedArticles = async (page) => {
        setIsLoading(true);
        try {
          const generateUrl = (article) => {
            const date = new Date(article.article_posted_date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `/${year}/${month}/${day}/${article.article_slug}`;
          };

          const { data: nonFeaturedData } = await axiosInstance.get(`/api/articles?sort=publishedAt:desc&populate[SEO]=*&populate[article_image]=*&filters[article_is_featured][$eq]=false&pagination[page]=${page}&pagination[pageSize]=25`);
          const newArticles = nonFeaturedData.data.map(article => ({ ...article, url: generateUrl(article) }));

          setNonFeaturedArticles(prevArticles => {
            const articlesMap = new Map(prevArticles.map(article => [article.id, article]));
            newArticles.forEach(article => articlesMap.set(article.id, article));
            return Array.from(articlesMap.values());
          });

          setHasMoreNonFeatured(page < nonFeaturedData.meta.pagination.pageCount);
        } catch (error) {
          console.error('Error fetching non-featured articles:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchNonFeaturedArticles(nonFeaturedPage);
    }
  }, [nonFeaturedPage]);

  const loadMoreNonFeaturedArticles = () => {
    if (hasMoreNonFeatured) {
      setNonFeaturedPage(prevPage => prevPage + 1);
    }
  };

  const fetchArticleDetails = async (year, month, day, title) => {
    const url = `/api/articles/${year}/${month}/${day}/${title}?populate[SEO]=*&populate[article_image]=*`;
    try {
      const { data } = await axiosInstance.get(url);
      setArticleDetails(data);
    } catch (error) {
      console.error(`Error fetching article details:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    allArticles,
    featuredArticles,
    nonFeaturedArticles,
    latestArticles,
    articleDetails,
    isLoading,
    loadMoreNonFeaturedArticles,
    hasMoreNonFeatured,
    fetchArticleDetails,
  };
};

export default useArticleData;
