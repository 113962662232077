// ArticlesDetails.jsx

import { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useArticleData from '../../hooks/useArticleData';
import './ArticleDetails.css';

const ArticleDetails = () => {
  const { year, month, day, slug } = useParams();
  console.log(year, month, day, slug);
  const { fetchArticleDetails, articleDetails, isLoading } = useArticleData();

  const fetchArticleDetailsRef = useRef(fetchArticleDetails);

  useEffect(() => {
    console.log('useEffect triggered');
    console.log('Year:', year);
    console.log('Month:', month);
    console.log('Day:', day);
    console.log('Slug:', slug);
  
    if (year && month && day && slug) {
      fetchArticleDetailsRef.current(year, month, day, slug)
        .then(response => {
          console.log('API response:', response);
        })
        .catch(error => {
          console.error('API error:', error);
        });
      window.scrollTo(0, 0);
    }
  }, [year, month, day, slug]); 

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  // Updated check for articleDetails
  if (!articleDetails || !articleDetails.data || !articleDetails.data.attributes) {
    return <div className="notFound">Article not found.</div>;
  }

  const articleAttributes = articleDetails.data.attributes;
  
  const imageUrl = articleAttributes.article_image.data?.[0]?.attributes?.url
    ? `${process.env.REACT_APP_STRAPI_BASE_URL}${articleAttributes.article_image.data[0].attributes.url}`
    : articleAttributes.article_scraped_image_url || 'path/to/default/image.jpg';

  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <div>
      <h1 className="articleTitle">{articleAttributes.article_title}</h1>
      <img src={imageUrl} alt={articleAttributes.article_title || 'Article Image'} className="articleImage" />
      <div className="articleContent" dangerouslySetInnerHTML={createMarkup(articleAttributes.article_content)} />
    </div>
  );
};

export default ArticleDetails;