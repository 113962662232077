// SocialMedia.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './SocialMedia.css';

const SocialMedia = ({ icons }) => {
  return (
    <div className="social-media-icons">
      {icons.map((icon, index) => (
        <a key={index} href={icon.url} className="social-media-link" target="_blank" rel="noopener noreferrer">
          <img src={icon.logo_url} alt={icon.platform} /> 
        </a>
      ))}
    </div>
  );
};

SocialMedia.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      logo_url: PropTypes.string.isRequired, 
      url: PropTypes.string.isRequired,
      platform: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SocialMedia;
