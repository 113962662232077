// usePageData.jsx

import { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';

const usePageData = (pageId) => {
  const [pageData, setPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPageData = async () => {
      setIsLoading(true);
      try {
          const response = await axiosInstance.get(`/api/pages/${pageId}?populate[SEO]=*&populate[heroImage][populate]=*`);
        setPageData(response.data);
      } catch (err) {
        console.error('Error fetching page data:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (pageId) {
      fetchPageData();
    }
  }, [pageId]);

  return { pageData, isLoading, error };
};

export default usePageData;
