import React, { useState, useEffect } from 'react';
import Tag from '../components/articles/Tag';
import axiosInstance from '../services/axiosConfig';
import './TagContainer.css';

const TagContainer = ({ onTagClick, selectedTag }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axiosInstance.get('/api/articles?populate[SEO]=*&populate[article_image]=*');
        const articleData = response.data;

        const categoryTags = articleData.data.map(article => article.attributes.article_category);
        const uniqueCategoryTags = [...new Set(categoryTags)];
        setTags(uniqueCategoryTags);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []); 

  return (
    <div className="tag-container">
      {tags.map((tag, index) => (
        <Tag
          key={index}
          text={tag}
          onClick={() => onTagClick(tag)}
          isSelected={tag === selectedTag}
        />
      ))}
    </div>
  );
};

export default TagContainer;
