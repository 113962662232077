import React from 'react';
import ArticleCard from '../articles/ArticleCard';
import useArticleData from '../../hooks/useArticleData';
import usePageData from '../../hooks/usePageData';
import './LocalNewsPage.css';

const LocalNewsPage = () => {
  const { allArticles, isLoading: articlesLoading } = useArticleData();
  const { pageData, isLoading: pageLoading, error } = usePageData(1);

  if (articlesLoading || pageLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const pageTitle = pageData?.attributes?.page_title;

  return (
    <>
      <div className="local-news-page-title">
        <h2>{pageTitle}</h2>
      </div>
      <section className="articles">
        {allArticles && allArticles.map((article, index) => (
          <ArticleCard key={index} article={article} />
        ))}
      </section>
    </>
  );
};

export default LocalNewsPage;