// Header.jsx
import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../common/Logo';
import NavigationMenu from '../navigation/NavigationMenu';
import './Header.css';

const Header = ({ siteData }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [condensedHeader, setCondensedHeader] = useState(false);
  const { navigationItems = [], socialMediaLinks = [], websiteLogo, websiteTitle } = siteData || {};

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const controlHeader = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setCondensedHeader(true);
      } else {
        setCondensedHeader(false);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader);

      return () => {
        window.removeEventListener('scroll', controlHeader);
      };
    }
  }, [lastScrollY]);

  return (
    <header className={`header ${condensedHeader ? 'condensed' : ''}`}>
      <Logo logoUrl={websiteLogo} siteName={websiteTitle} />
      <FaBars className={`hamburger ${isMenuOpen ? 'hide' : ''}`} onClick={toggleMenu} />
      {isMenuOpen && <FaTimes className="close-menu is-active" onClick={toggleMenu} />}
      <NavigationMenu items={navigationItems} socialMediaLinks={socialMediaLinks} isMenuOpen={isMenuOpen} onLinkClick={toggleMenu} />
    </header>
  );
};

export default Header;
