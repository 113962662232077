// AboutHeroSection.jsx
import React from 'react';

const AboutHeroSection = ({ headline, htmlContent, imageUrl, imageAlt }) => {
    return (
      <section className="about-hero-section">
        <div className="hero-content">
          <h1>{headline}</h1>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
        {imageUrl && <img src={imageUrl} alt={imageAlt} className="about-hero-image" />}
      </section>
    );
};

export default AboutHeroSection;
