// Logo.jsx
import React from 'react';
import './Logo.css';

const Logo = ({ logoUrl, siteName }) => (
  <a href="/" className="logo-link">
    <div className="logo-container">
      {logoUrl && <img src={logoUrl} alt={siteName} className="logo-image" />}
      <span className="site-name"><strong>{siteName}</strong></span>
    </div>
  </a>
);

export default Logo;
