// useAboutData.jsx
import { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';

const useAboutData = () => {
  const [aboutData, setAboutData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axiosInstance.get(`/api/aboutpage?populate[blocks][populate][image][populate]=*`);
          setAboutData(response.data);
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
        }
      };

    fetchData();
  }, []);

  return { aboutData, isLoading, error };
};

export default useAboutData;
